import React, { PureComponent } from "react"
import css from "./index.module.scss"
import fontType from "../../../../helpers/fontType.module.scss"
import { FaArrowRight } from "react-icons/fa"

import SignupLink from "../../../SignupLink"
import Grid from "../../../Grid"
import Button from "../../../Button"
import Quotes from "./Quotes"

class CustomerSuccess extends PureComponent {
  render() {
    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.image}>
          <Quotes />
        </div>
        <div className={css.text}>
          <h2>What they’re saying on Shopify…</h2>
          <p>
            Our five-star customer success team are smart, friendly, and here to
            get you results
          </p>
          <SignupLink el={Button} className={css.button} purpose="primary">
            Try Free for 30 Days &nbsp; <FaArrowRight />
          </SignupLink>
          <p className={fontType.b4}>No contract. Cancel anytime</p>
        </div>
      </Grid>
    )
  }
}

export default CustomerSuccess
