import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Grid from "../../../Grid"
import css from "./index.module.scss"

const OnlineStores = () => {
  const data = useStaticQuery(query)
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h3 className={css.title}>
        Working with hundreds of Shopify Plus merchants
      </h3>
      <ul className={css.store_logos}>
        {Object.keys(data).map((image) => (
          <li key={image}>
            <Img
              fixed={data[image].childImageSharp.fixed}
              alt={`Referralcandy enterprise - ${image.replace(
                "logo_",
                ""
              )} successful referral marketing referral program software`}
            />
          </li>
        ))}
      </ul>
    </Grid>
  )
}

export default OnlineStores

const query = graphql`
  query {
    logo_riffraff: file(
      relativePath: { eq: "images/store_logos/logo-enterprise-riffraff.png" }
    ) {
      childImageSharp {
        fixed(width: 54, height: 43) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_roro: file(
      relativePath: { eq: "images/store_logos/logo-enterprise-roro.png" }
    ) {
      childImageSharp {
        fixed(width: 41, height: 41) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_miaomiao: file(
      relativePath: { eq: "images/store_logos/logo-enterprise-miaomiao.png" }
    ) {
      childImageSharp {
        fixed(width: 58, height: 21) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_secretlab: file(
      relativePath: { eq: "images/store_logos/logo-enterprise-secretlab.png" }
    ) {
      childImageSharp {
        fixed(width: 90, height: 29) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_flattummytea: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy pricing - flat tummy tea successful referral marketing referral program software.png"
      }
    ) {
      childImageSharp {
        fixed(width: 141, height: 16) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
