import React from "react"

import Layout from "../components/Layout"
import Main from "../components/Sections/ShopifyPlus/Main"
import OnlineStores from "../components/Sections/ShopifyPlus/OnlineStores"
import AdditionalFeatures from "../components/Sections/ShopifyPlus/AdditionalFeatures"
import Stats from "../components/Sections/Stats"
import CustomerSuccess from "../components/Sections/ShopifyPlus/CustomerSuccess"

const metadata = {
  title: "Shopify Plus | ReferralCandy",
}
const ShopifyPlusPage = () => {
  return (
    <Layout metadata={metadata}>
      <Main />
      <OnlineStores />
      <Stats />
      <CustomerSuccess />
      <AdditionalFeatures />
    </Layout>
  )
}

export default ShopifyPlusPage
