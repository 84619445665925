import React, { PureComponent } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import classnames from "classnames"
import { FaStar } from "react-icons/fa"
import { StaticQuery, graphql } from "gatsby"
import css from "./Quotes.module.scss"

const DEFAULT_REVIEWS = [
  {
    key: "blume",
    name: "Blume",
    date: "August 31, 2019",
    description:
      "We’ve been using ReferralCandy for over a year and couldn’t be happier. Karen is the best account rep and always willing to help us solve any issues by going above and beyond!",
  },
  {
    key: "toutcuit",
    name: "Tout cuit",
    date: "June 14, 2019",
    description:
      "Best referral app for Shopify, period. We’ve successfully implemented ReferralCandy to our store after trying several other apps that did not meet our needs. ReferralCandy is well designed and easy to work around.",
    more_description:
      "Lastly I want to point out that the customer service is excellent. Karen, Lorraine, Jon and Derek were all genuinely nice people who solved my problems in a few seconds.The support team is super quick and accessible. 10/ 10 would install again!",
  },
]

const REVIEW_CLASSNAMES = {
  enter: css["in"],
  enterActive: css.in_active,
  exit: css["out"],
  exitActive: css.out_active,
}
const ANIMATION_TOTAL_TIME = 1250

const ROTATE_TIME = 10000

class Quotes extends PureComponent {
  state = {
    review: this.props.reviews[0],
    // Dynamic property represents whether or not its server side rendered or not
    // When its server side rendered, we want all the content to be available (SEO reasons)
    // When its loaded, it's hidden behind a carousel click
    dynamic: false,
  }
  nextReviewTimeout = null

  scheduleNextReview = () => {
    const reviews = this.props.reviews
    clearTimeout(this.nextReviewTimeout)
    this.nextReviewTimeout = setTimeout(() => {
      const index = reviews.indexOf(this.state.review)
      const nextIndex = index >= reviews.length - 1 ? 0 : index + 1
      this.setState({ review: reviews[nextIndex] })
      this.scheduleNextReview()
    }, ROTATE_TIME)
  }

  waitForScrollIntoView = () => {
    if (!this.navRef) return
    const target = this.navRef
    const observer = new IntersectionObserver(
      (entries) => {
        const visible = entries[0] && entries[0].isIntersecting
        if (visible) {
          this.scheduleNextReview()
        } else {
          clearTimeout(this.nextReviewTimeout)
        }
      },
      {
        threshold: 0.25,
      }
    )
    observer.observe(target)

    this.clearScrollIntoView = () => observer.unobserve(target)
  }

  componentDidMount() {
    this.setState({
      review: this.props.reviews[0],
      dynamic: true,
    })
    this.waitForScrollIntoView()
  }

  componentWillUnmount() {
    clearTimeout(this.nextReviewTimeout)
    if (this.clearScrollIntoView) this.clearScrollIntoView()
  }

  render() {
    const activeReview = this.state.review
    const renderedReviews = this.state.dynamic
      ? [activeReview]
      : this.props.reviews

    return (
      <div className={css.container} ref={(ref) => (this.navRef = ref)}>
        <TransitionGroup>
          {renderedReviews.map((review) => {
            const key = review.key
            return (
              <CSSTransition
                classNames={REVIEW_CLASSNAMES}
                timeout={ANIMATION_TOTAL_TIME}
                key={key}
              >
                <a
                  href="https://apps.shopify.com/referralcandy/reviews"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classnames(css.content, css.review)}
                >
                  <p className={css.title}>{review.name}</p>
                  <div className={css.details}>
                    <div className={css.rating}>
                      <p>Rating</p>
                      <div className={css.star_container}>
                        <FaStar className={css.star} />
                        <FaStar className={css.star} />
                        <FaStar className={css.star} />
                        <FaStar className={css.star} />
                        <FaStar className={css.star} />
                      </div>
                    </div>
                    <div className={css.date}>
                      <p>Posted</p>
                      <p>{review.date}</p>
                    </div>
                  </div>
                  <p className={css.description}>{review.description}</p>
                  <p className={css.description}>{review.more_description}</p>
                </a>
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      </div>
    )
  }
}

const query = graphql`
  query {
    avatar: file(relativePath: { eq: "images/samantha.png" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Quotes data={data} reviews={DEFAULT_REVIEWS} {...props} />
    )}
  />
)
