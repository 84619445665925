import React, { PureComponent } from "react"
import css from "./index.module.scss"
import Grid from "../../../Grid"
import Button from "../../../Button"
import { FaArrowRight } from "react-icons/fa"

import BANNER_IMAGE from "../../../../assets/images/shopify_plus/shopify-plus-certified-app-black.png"

class Main extends PureComponent {
  render() {
    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.text}>
          <h1>
            Grow like top
            <br />
            Shopify Plus
            <br />
            brands
          </h1>
          <p>
            <Button
              purpose="primary"
              href="https://help.referralcandy.com/support/tickets/new"
              target="_blank"
            >
              Let's Talk &nbsp; <FaArrowRight />
            </Button>
          </p>
        </div>
        {
          <div className={css.image}>
            <div>
              <img
                src={BANNER_IMAGE}
                alt="Shopify Plus banner"
                height="300"
                width="300"
              />
            </div>
          </div>
        }
      </Grid>
    )
  }
}

export default Main
